<template>
  <v-card>
    <v-card-subtitle>
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="customerNumber"
            :items="customers"
            :loading="loadingCustomers"
            :search-input.sync="searchCustomer"
            :placeholder="$t('customer name or number')"
            item-text="text"
            item-value="value"
            hide-no-data
            hide-selected
            no-filter
            clearable
            single-line
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="staffUsername"
            :items="staffUsernames"
            :loading="loadingStaffUsernames"
            :placeholder="$t('staff username')"
            item-text="value"
            item-value="value"
            hide-selected
            hide-no-data
            clearable
            single-line
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="view"
            :items="views"
            item-text="value"
            item-value="value"
            :search-input.sync="searchView"
            :loading="loadingViews"
            :placeholder="$t('backend view')"
            hide-selected
            hide-no-data
            clearable
            single-line
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="httpMethod"
            :items="httpMethodes"
            :placeholder="$t('HTTP method')"
            item-text="text"
            item-value="value"
            hide-selected
            clearable
            single-line
            :return-object="false"
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>

        <v-col cols="2">
          <v-combobox
            v-model="httpStatus"
            :items="httpStatuses"
            :placeholder="$t('HTTP status')"
            item-text="text"
            item-value="value"
            hide-selected
            clearable
            single-line
            :return-object="false"
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
            @click:append-outer="
              options.page = 1;
              getLogs();
            "
            @keyup.enter.native="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="ipAddr"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('IP address')"
            single-line
            hide-details
            @click:append-outer="
              options.page = 1;
              getLogs();
            "
            @keyup.enter.native="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="3">
          <date-picker
            v-model="dateAfter"
            :label="$t('start date')"
            hide-icon
            clearable
            no-form
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
        <v-col cols="3">
          <date-picker
            v-model="dateBefore"
            :label="$t('end date')"
            hide-icon
            clearable
            no-form
            @input="
              options.page = 1;
              getLogs();
            "
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="logs"
        :options.sync="options"
        :server-items-length="itemsTotal"
        :loading="loading"
        :disable-sort="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="created"
        sort-desc
        :hide-default-footer="itemsTotal <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
        single-expand
        show-menu
        local-storage-name="accessLogList"
        open-expand-by-dbl-click-row
        open-expand-by-click-row
        dense
      >
        <template v-slot:item.created="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatDateTime(item.created) }}
          </div>
        </template>

        <template v-slot:item.elapsed_time="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatDuration(item.elapsed_time, true) }}
          </div>
        </template>

        <template v-slot:item.secure="{ item }">
          <v-simple-checkbox v-model="item.secure" disabled />
        </template>

        <template v-slot:item.staff="{ item }">
          <v-simple-checkbox v-model="item.staff" disabled />
        </template>

        <template v-slot:item.url="{ item }">
          <span
            class="d-inline-block text-truncate"
            :style="'max-width:' + urlSize + 'px;'"
          >
            {{ item.url }}
          </span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <access-log-details
              :id="item.id"
              :base-url="getBaseUrl()"
              class="my-3"
            />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDuration from "@/utils/mixins/formatDuration";
import DataTableExtended from "@/components/basics/DataTableExtended";
import DatePicker from "@/components/basics/DatePicker";
import AccessLogDetails from "@/components/staff/AccessLogDetails";

export default {
  name: "StaffAccessLogs",
  mixins: [showErrors, isMobile, formatDateTime, formatDuration],
  components: {
    DataTableExtended,
    DatePicker,
    AccessLogDetails,
  },
  data: () => ({
    expanded: [],
    loading: false,
    loadingDetails: false,
    itemsPerPage: 50,
    itemsTotal: 0,
    options: {},
    logs: [],
    details: null,
    search: null,
    ipAddr: null,
    httpMethod: null,
    httpMethodes: [
      { text: "GET", value: "GET" },
      { text: "HEAD", value: "HEAD" },
      { text: "POST", value: "POST" },
      { text: "PUT", value: "PUT" },
      { text: "PATCH", value: "PATCH" },
      { text: "DELETE", value: "DELETE" },
      { text: "CONNECT", value: "CONNECT" },
      { text: "OPTIONS", value: "OPTIONS" },
    ],
    httpStatus: null,
    httpStatuses: [
      { text: "200 - OK", value: "200" },
      { text: "201 - Created", value: "201" },
      { text: "300 - Multiple Choices", value: "300" },
      { text: "301 - Moved Permanently", value: "301" },
      { text: "302 - Found (Moved Temporarily)", value: "302" },
      { text: "400 - Bad Request", value: "400" },
      { text: "401 - Unauthorized", value: "401" },
      { text: "403 - Forbidden", value: "403" },
      { text: "404 - Not Found", value: "404" },
      { text: "405 - Method Not Allowed", value: "405" },
      { text: "406 - Not Acceptable", value: "406" },
      { text: "408 - Request Timeout", value: "408" },
      { text: "409 - Conflict", value: "409" },
      { text: "415 - Unsupported Media Type", value: "415" },
      { text: "429 - Too Many Requests", value: "429" },
      { text: "500 - Internal Server Error", value: "500" },
      { text: "501 - Not Implemented", value: "501" },
      { text: "502 - Bad Gateway", value: "502" },
      { text: "504 - Gateway Timeout", value: "504" },
    ],
    customerNumber: null,
    customers: [],
    loadingCustomers: false,
    searchCustomer: null,
    view: null,
    views: [],
    searchView: null,
    loadingViews: false,
    staffUsername: null,
    staffUsernames: [],
    loadingStaffUsernames: false,
    httpCode: null,
    httpCodes: [],
    loadingHttpCodes: false,
    dateAfter: null,
    dateBefore: null,
  }),
  computed: {
    headers() {
      var that = this;
      return [
        {
          text: this.$i18n.t("created"),
          value: "created",
        },
        {
          text: this.$i18n.t("elapsed time"),
          value: "elapsed_time",
          hidden: true,
        },
        {
          text: this.$i18n.t("IP"),
          value: "ip",
        },
        {
          text: this.$i18n.t("customer number"),
          value: "customer_number",
        },
        {
          text: this.$i18n.t("customer type"),
          value: "customer_type",
          hidden: true,
        },
        {
          text: this.$i18n.t("is staff"),
          value: "staff",
          hidden: true,
        },
        {
          text: this.$i18n.t("staff username"),
          value: "staff_username",
          hidden: true,
        },
        {
          text: this.$i18n.t("secure"),
          value: "secure",
          hidden: true,
        },
        {
          text: this.$i18n.t("HTTP method"),
          value: "method",
        },
        {
          text: this.$i18n.t("status"),
          value: "status",
        },
        {
          text: this.$i18n.t("URL"),
          value: "url",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    urlSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getLogs();
      },
      deep: true,
    },
    search() {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.options.page = 1;
        that.getLogs();
      }, 850);
    },
    searchCustomer() {
      var that = this;
      clearTimeout(that.delayTimer);
      that.delayTimer = setTimeout(function () {
        that.options.page = 1;
        that.searchCustomers();
      }, 850);
    },
  },
  methods: {
    getBaseUrl() {
      var url = new URL(this.$config.backendUrl);
      return url.protocol + "//" + url.host + "/";
    },
    getParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      return {
        page_size: itemsPerPage || 15,
        page: page || 1,
        ordering:
          sortBy.length > 0
            ? (sortDesc.length > 0 && sortDesc[0] ? "-" : "") + sortBy[0]
            : null,
        search: this.search || null,
        ip: this.ipAddr || null,
        customer_number: this.customerNumber || null,
        method: this.httpMethod || null,
        view: this.view || null,
        staff_username: this.staffUsername || null,
        status: this.httpCode || null,
        created_after:
          this.dateAfter != null
            ? this.$moment(this.dateAfter).toISOString(true)
            : null,
        created_before:
          this.dateBefore != null
            ? this.$moment(this.dateBefore)
                .add(1, "days")
                .subtract(1, "seconds")
                .toISOString(true)
            : null,
        status: this.httpStatus || null,
      };
    },
    getLogs() {
      var that = this;
      that.loading = true;
      this.$http
        .get("logs/access-logs", {
          params: this.getParams(),
          baseURL: this.getBaseUrl(),
        })
        .then((response) => {
          that.logs = response.data.results;
          that.itemsTotal = response.data.count;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    searchCustomers() {
      var that = this;
      that.loadingCustomers = true;
      this.$http
        .get("customers", {
          params: {
            q: this.searchCustomer,
            rated: true,
            unfiltered: true,
            extended: true,
            limit: 20,
          },
        })
        .then((response) => {
          that.customers = response.data.map(function (item) {
            return {
              text: item.partner_type + " - " + item.number + " - " + item.name,
              value: item.number,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingCustomers = false;
        });
    },
    getViews(id) {
      var that = this;
      that.loadingViews = true;
      this.$http
        .get("logs/access-logs/views", { baseURL: this.getBaseUrl() })
        .then((response) => {
          that.views = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingViews = false;
        });
    },
    getStaffUsernames(id) {
      var that = this;
      that.loadingStaffUsernames = true;
      this.$http
        .get("logs/access-logs/staff-usernames", { baseURL: this.getBaseUrl() })
        .then((response) => {
          that.staffUsernames = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingStaffUsernames = false;
        });
    },
    getHttpCodes(id) {
      var that = this;
      that.loadingHttpCodes = true;
      this.$http
        .get("logs/access-logs/http-codes", { baseURL: this.getBaseUrl() })
        .then((response) => {
          that.httpCodes = response.data;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loadingHttpCodes = false;
        });
    },
  },
  mounted() {
    this.getViews();
    this.getStaffUsernames();
    this.getHttpCodes();
    this.dateAfter = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    this.dateBefore = this.$moment().format("YYYY-MM-DD");
  },
};
</script>

<i18n>
{
  "en": {
    "created": "Created",
    "elapsed time": "Elapsed time",
    "customer number": "Customer number",
    "search": "Search query",
    "IP address": "IP address",
    "IP": "IP",
    "HTTP method": "HTTP method",
    "secure": "Secure",
    "status": "Status",
    "URL": "URL",
    "customer name or number": "customer name or number",
    "customer type": "Customer type",
    "backend view": "Backend view",
    "staff username": "Staff username",
    "is staff": "Is staff",
    "start date": "Start date",
    "end date": "End date",
    "HTTP status": "HTTP Status"
  },
  "de": {
    "created": "Erzeugt",
    "elapsed time": "Benötigte Zeit",
    "customer number": "Kundennummer",
    "search": "Suchanfrage",
    "IP address": "IP Adresse",
    "IP": "IP",
    "HTTP method": "HTTP Methode",
    "secure": "Verschlüsselt",
    "status": "Status",
    "URL": "URL",
    "customer name or number": "Kundenname oder Nummer",
    "customer type": "Kundentyp",
    "backend view": "Backend View",
    "staff username": "Mitarbeiter Benutzername",
    "is staff": "Ist Mitarbeiter",
    "start date": "Datum Begin",
    "end date": "Datum Ende",
    "HTTP status": "HTTP Status"
  }
}
</i18n>
